import React, { useState, useEffect, useRef } from 'react'
import { DateFormatWithSlash, DateAndTimestamp } from '../../utils/DateFormatWithSlash'
import { MdCreateNewFolder, Md123 } from 'react-icons/md';
import { Oval, ThreeDots } from 'react-loader-spinner'
import { RiEditBoxFill } from 'react-icons/ri';
import { MdDelete } from 'react-icons/md';
import Swal from 'sweetalert2';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    TextField,
    MenuItem,
    InputLabel,
    FormControl,
    Select,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel
} from '@material-ui/core';
import DialogTitle from '@mui/material/DialogTitle';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Slide from '@mui/material/Slide';
import dayjs from 'dayjs';
import searchIcon from '../../data/searchIcon.png'
import axios from "axios";
import { useStateContext } from '../../contexts/ContextProvider';
import { MdCancel } from 'react-icons/md'
import S3FileUpload from 'react-s3';
import DrawerGrowthBoard from "../../pages/new/DrawerGrowthBoard";
import { Toast } from "../../components/General";
import { S3Upload } from '../../utils/S3Upload';
import { IoEyeOutline } from "react-icons/io5";
window.Buffer = window.Buffer || require("buffer").Buffer;

const S3_BUCKET = 'tek-crm';
const REGION = 'us-east-1';
const ACCESS_KEY = 'AKIAUPLSLQUZCEBB22HJ';
const SECRET_ACCESS_KEY = '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';

const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const validationSchema = yup.object({
    name: yup
        .string()
        .min(5, 'Too Short!')
        .max(70, 'Too Long!')
        .required('Task name is required'),
    /*desc: yup
        .string()
        .min(6, 'Too Short!')
        .optional(),
    //.required('Description is required'),
    start_date: yup
        .date().required('Start Date is required')
        .optional(),
    end_date: yup
        .date()
        //.required('End Date is required')
        .min(yup.ref('start_date'), 'End date cannot be earlier than start date')
        .optional(),*/
    /*status: yup
      .string()
      .required('Status is required'),*/
});

const TaskTable = ({ tasks = [], getSelectedTasks, project_id, refresher, selectedTask, setLoading, loading }) => {
    const [open, setOpen] = React.useState(false);
    const [mode, setMode] = useState('');
    const [editValues, setEditValues] = useState({});
    const [selectedFile, setSelectedFile] = useState([]);
    const [search, setSearch] = useState(true)
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [drawerLoading, setDrawerLoading] = useState(false);

    const [marketingPhases, setMarketingPhases] = useState([]);
    const [phaseOneTasks, setPhaseOneTasks] = useState([]);
    const [phaseTwoTasks, setPhaseTwoTasks] = useState([]);
    const [phaseThirdTasks, setPhaseThirdTasks] = useState([]);
    const [phaseFourthTasks, setPhaseFourthTasks] = useState([]);
    const fileInputRef = useRef(null);
    
    // const[attachments,setAttachments] = useState([]);
    const { user } = useStateContext();
    const { role } = user.user;

    const [btnLoading, setBtnLoading] = useState(false);

    const [selectFile, setSelectFile] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);


    // const handleFileInput = (e) => {
    //     // console.log(e.target.files);
    //     setSelectedFile(e.target.files);
    // }

    function handleSearchInputChange(event) {
        const { value } = event.target;
        setSearchTerm(value);
    }

    const formSubmit = (e) => {
        e.preventDefault();
    }

    // const handleUpload = async (file, resetForm) => {

    //     setBtnLoading(true);

    //     const attachments = [];
    //     for (let i = 0; i < file.length; i++) {
    //         const mimeType = getFileMimeType(file[i]);
    //         console.log('MIME type:', mimeType);

    //         try {
    //             const data = await S3FileUpload.uploadFile(file[i], config);

    //             const attachment = {
    //                 path: data.location,
    //                 mime_type: mimeType,
    //             };

    //             attachments.push(attachment);
    //         } catch (error) {
    //             console.error("ERROR IN UPLOAD", error);
    //         }
    //     }

    //     createTask(attachments, resetForm);
    // }






    const getFileMimeType = (file) => {
        const fileNameParts = file.name.split('.');
        const fileExtension = fileNameParts[fileNameParts.length - 1];
        const mimeType = getMimeTypeFromExtension(fileExtension);
        return mimeType;
    };

    const getMimeTypeFromExtension = (extension) => {
        const mimeTypes = {
            png: 'file/png',
            jpg: 'file/jpg',
            jpeg: 'file/jpeg',
            gif: 'file/gif',
            pdf: 'file/pdf',
            zip: 'file/zip',
            docx: 'file/docx'
        };
        return mimeTypes[extension.toLowerCase()] || '';
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteTask(id)
            }
        })

    }

    const deleteTask = async (id) => {
        
        await axios.delete(`${process.env.REACT_APP_BASE_URL}project-task/${id}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((res) => {
            if (res.status === 200) {
                refresher();

                Toast.fire({
                    icon: "success",
                    title: "Task Deleted Successfully",
                });
            }
        })
        .catch((error) => {
            console.log(error);

            Toast.fire({
                icon: "success",
                title: "Something went wrong!, Please try again Later"
            });
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const handleClickOpen = (item, mode) => {

        formik.resetForm();

        console.log(mode);

        switch (mode) {
            case 'edit':

                console.log(editValues);
                
                setMode('edit');
                setEditValues(item);
                console.log(editValues);
                setOpen(true);
                break;
            default:
                setMode('create');
                setEditValues({})
                setOpen(true);
        }
    };

    const resetFileInput = () => {
        // This will clear the file input
        fileInputRef.current.value = '';
    };

    const handleClose = () => {
        setOpen(false);
        setEditValues({});
        setSelectFile(false);
        resetFileInput();
        formik.resetForm();
    };

    const handleFileInput = (e) => {
        setSelectFile(e.target.files[0]);
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            desc: '',
            // start_date: null,
            // end_date: null,
            timeline: '',
            marketing_phase_id: '',
            //status: null
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {


            // console.log(selectFile);

            handleUpload(selectFile, resetForm);

            // if (mode === 'create') {

            //     handleUpload(selectedFile, resetForm);

            // } else {

            //     EditTask(resetForm);
            // }
        },
    });

    const handleUpload = async (selectFile, resetForm) => {
        let response = false;

        if (selectFile) {
            response = await S3Upload(selectFile.type, selectFile.name, selectFile);
        }

        if (mode == 'edit') {
            EditTask(response, resetForm);
        } else {
            createTask(response, resetForm);
        }
    }

    const createTask = async (file_uri = "", resetForm) => {

        setBtnLoader(true);

        const data = {
            "project_id": project_id,
            "name": formik.values.name,
            "timeline": formik.values.timeline,
            "marketing_phase_id": formik.values.marketing_phase_id,

            "description": formik.values.desc,
            // "start_date": formik.values.start_date,
            // "end_date": formik.values.end_date,
            "file_path": file_uri || null
        };

        await axios.post(`${process.env.REACT_APP_BASE_URL}project-task`, { ...data }, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((res) => {
            if (res.status === 200) {
                // refresher();
                getTaskByMarketingPhasesOne();
                getTaskByMarketingPhasesTwo();
                getTaskByMarketingPhasesThird();
                getTaskByMarketingPhasesFourth();
                
                handleClose();
                resetForm({ values: '' });

                Toast.fire({
                    icon: 'success',
                    title: 'Task Created successfully!',
                });
            }
        })
        .catch((error) => {

            Toast.fire({
                icon: 'error',
                title: 'Something went wrong!, Please try again later.',
            });
        })
        .finally(() => {
            setBtnLoader(false);
        });
    };

    const EditTask = async (file_uri = "", resetForm) => {

        setBtnLoader(true);

        const data = {
            "project_id": project_id,
            "name": formik.values.name,
            "timeline": formik.values.timeline,
            "marketing_phase_id": formik.values.marketing_phase_id,

            "description": formik.values.desc,
            // "start_date": formik.values.start_date,
            // "end_date": formik.values.end_date,
            "file_path": file_uri || null
        };

        // console.log(data);

        await axios.put(`${process.env.REACT_APP_BASE_URL}project-task/${editValues.id}`, {
            ...data,
            id: editValues.id
        }, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => {
                if (res.status === 200) {
                    getTaskByMarketingPhasesOne();
                    getTaskByMarketingPhasesTwo();
                    getTaskByMarketingPhasesThird();
                    getTaskByMarketingPhasesFourth();

                    handleClose();
                    resetForm({ values: '' });

                    Toast.fire({
                        icon: 'success',
                        title: 'Task Updated successfully!',
                    });
                }
            })
            .catch((error) => {

                Toast.fire({
                    icon: 'error',
                    title: 'Something went wrong!, Please try again later.',
                });
            })
            .finally(() => {
                setBtnLoader(false);
            });
    };

    const getMarketingPhases = async () => {

        await axios.get(`${process.env.REACT_APP_BASE_URL}get_marketing_phases`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((response) => {
            setMarketingPhases(response.data.data);

            console.log(marketingPhases);
        })
        .catch((err) => {
            console.log('err====', err);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const getTaskByMarketingPhasesOne = async () => {

        await axios.get(`${process.env.REACT_APP_BASE_URL}get_task_by_phase/${project_id}/1`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((response) => {
            setPhaseOneTasks(response.data.data);
        })
        .catch((err) => {
            console.log('err====', err);
        })
        .finally(() => {
            // setLoading(false);
        });
    };

    const getTaskByMarketingPhasesTwo = async () => {

        await axios.get(`${process.env.REACT_APP_BASE_URL}get_task_by_phase/${project_id}/2`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((response) => {
            setPhaseTwoTasks(response.data.data);
        })
        .catch((err) => {
            console.log('err====', err);
        })
        .finally(() => {
            // setLoading(false);
        });
    };
 
    const getTaskByMarketingPhasesThird = async () => {

        await axios.get(`${process.env.REACT_APP_BASE_URL}get_task_by_phase/${project_id}/3`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((response) => {
            setPhaseThirdTasks(response.data.data);
        })
        .catch((err) => {
            console.log('err====', err);
        })
        .finally(() => {
            // setLoading(false);
        });
    };
 
    const getTaskByMarketingPhasesFourth = async () => {

        await axios.get(`${process.env.REACT_APP_BASE_URL}get_task_by_phase/${project_id}/4`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((response) => {
            setPhaseFourthTasks(response.data.data);
        })
        .catch((err) => {
            console.log('err====', err);
        })
        .finally(() => {
            // setLoading(false);
        });
    };

    const getTaskByMarketingPhases = async (phaseId) => {

        await axios.get(`${process.env.REACT_APP_BASE_URL}get_task_by_phase/${project_id}/${phaseId}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((response) => {

            if(phaseId == 1){
                setPhaseOneTasks(response.data.data);
            }

            if(phaseId == 2){
                setPhaseTwoTasks(response.data.data);
            }

            if(phaseId == 3){
                setPhaseThreeTasks(response.data.data);
            }

            if(phaseId == 4){
                setPhaseFourTasks(response.data.data);
            }
        })
        .catch((err) => {
            console.log('err====', err);
        })
        .finally(() => {
            // setLoading(false);
        });
    };

    // useEffect(() => {
    //     getMarketingPhases();
    //     getTaskByMarketingPhasesOne();
    //     getTaskByMarketingPhasesTwo();
    //     getTaskByMarketingPhasesThird();
    //     getTaskByMarketingPhasesFourth();
    // }, []);

    useEffect(() => {

        getMarketingPhases();
        getTaskByMarketingPhasesOne();
        getTaskByMarketingPhasesTwo();
        getTaskByMarketingPhasesThird();
        getTaskByMarketingPhasesFourth();

    }, [project_id]);

    useEffect(() => {


        formik.setValues({
            name: editValues.name || '',
            desc: editValues.description || '',
            timeline: editValues.timeline || '',
            marketing_phase_id: editValues.marketing_phase_id || '',
        });

        // console.log(editValues);

        // formik.setValues({
        //     name: editValues.name || '',
        //     // start_date: dayjs(editValues.start_date) || null,
        //     // end_date: dayjs(editValues.end_date) || null,
        //     // status: editValues.status === 0 ? 0 : 2,
        //     desc: editValues.description || '',
        //     // marketing_phase_id_id: editValues.marketing_phase_id_id || ''
        // });
    }, [editValues]);

    /*if (loading) {
        return (
            <div className=' justify-center items-center flex h-[80vh]'>
                <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#F37A20"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
            </div>

        )
    }*/

    const submitRequest = async (item) => {
        setLoading(true);
        const data = {
            "project_id": item.project_id,
            "name": item.name,
            "status": 1,
            "description": item.description,
            "start_date": item.start_date,
            "end_date": item.end_date,
        }
        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}project-task/${item.id}`, {
                ...data,
                id: item.id
            }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        refresher();
                        setLoading(false);
                        Swal.fire(
                            {
                                icon: 'success',
                                title: 'Request Sent successfully!',
                                showConfirmButton: false,
                                timer: 1000
                            }
                        )
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1000
                    })
                })
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }



    // opening drawer instead of bottom data
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [currentTask, setCurrentTask] = useState(null);

    const toggleDrawer = (open) => {
        setDrawerOpen(open);
        if (!open) {
            setCurrentTask(null);
        }
    };

    const handleTaskClick = (task) => {
        setCurrentTask(task);
        // toggleDrawer(true);
    };



    return (

        <>
            <div className='flex items-center'>
                <div className={`flex relative items-center`}>

                </div>
                {role !== 'client' ?
                    // <div className="markting_task main-btn-txt">
                    <div className="main-btn-txt">
                        <button onClick={handleClickOpen} className="btn-banner">
                            Create A Task
                        </button>
                    </div>
                    : null}
            </div>

            <div className='main_style_box project_listing_accordion growth-board'>
                <ul className="main_header text-center">
                    <li> Scope Stage</li>
                 
                </ul>

                <ul className="main_header active">
                    <li>Marketing Activities Phase 1</li>
                    {/* <li className='status-title'>Start Date</li> */}
                    {/* <li className='status-title'>End Date</li> */}
                    <li className='status-title'>Timeline</li>
                    {/* <li className='status-title'>Task Request</li> */}
                    {role !== 'client' &&
                        <li className='action-title'>Action</li>
                    }
                </ul>


                <div className="">
                    <div className="project_content_accordion listing_class active">
                        {phaseOneTasks.length !== 0 ? (
                            <>
                                {phaseOneTasks.filter(task => task.name.toLowerCase().includes(searchTerm.toLowerCase())).map((item, index) => (
                                    <ul key={index} className="main_listing custom_lisitng">
                                        <li>
                                            <h4 onClick={() => handleTaskClick(item)}>{item.name}</h4>
                                        </li>
                                        {/* <li className='status'>
                                            <div className="date">{item.start_date ? DateFormatWithSlash(item.start_date) : 'No date added'}</div>
                                        </li>
                                        <li className='status'>
                                            <div className="date">{item.end_date ? DateFormatWithSlash(item.end_date) : 'No date added'}</div>
                                        </li> */}

                                        <li className='status'>
                                            <div className="date">{item.timeline} weeks</div>
                                        </li>

                                        {/* <li className='status'>
                                            {role === 'client' && item.status === 0 && (
                                                <div className="task_list request" onClick={() => submitRequest(item)}>
                                                    Request
                                                </div>
                                            )}
                                            {role === 'client' && item.status === 1 && (
                                                <div className="task_list review">Request In Review</div>
                                            )}
                                            {role !== 'client' && item.status === 1 && (
                                                <div className="task_list request-received">Request Received</div>
                                            )}
                                            {item.status === 2 && (
                                                <div className="task_list task-progress">Task in Progress</div>
                                            )}
                                            {item.status === 3 && (
                                                <div className="task_list success">Task Completed</div>
                                            )}
                                        </li> */}
                                        {role !== 'client' && (
                                            <li className='action'>
                                                <div className="flex gap-2 justify-content-center">
                                                    <RiEditBoxFill className='cursor-pointer' onClick={() => handleClickOpen(item, 'edit')} color="orange" size={28} />
                                                    <MdDelete className='cursor-pointer' onClick={() => handleDelete(item.id)} color="red" size={28} />
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                ))}
                            </>
                        ) : (
                            <div className='flex justify-center items-center p-4 bg-[white]'>
                                <p className='text-center text-[11px] text-[#979797]'>Project has yet no tasks</p>
                            </div>
                        )}

                        {currentTask && (
                            <DrawerGrowthBoard
                                loading={false} // Add actual loading state if needed
                                open={drawerOpen}
                                toggleDrawer={toggleDrawer}
                                task={currentTask}
                                tasks={tasks}
                            // Include other necessary props here
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className='main_style_box project_listing_accordion growth-board'>
                <ul className="main_header text-center">
                    <li> Design Stage</li>
                 
                </ul>

                <ul className="main_header active">
                    <li>Marketing Activities Phase 2</li>
                    {/* <li className='status-title'>Start Date</li> */}
                    {/* <li className='status-title'>End Date</li> */}
                    <li className='status-title'>Timeline</li>
                    {/* <li className='status-title'>Task Request</li> */}
                    {role !== 'client' &&
                        <li className='action-title'>Action</li>
                    }
                </ul>

                <div className="">
                    <div className="project_content_accordion listing_class active">
                        {phaseTwoTasks.length !== 0 ? (
                            <>
                                {phaseTwoTasks.filter(task => task.name.toLowerCase().includes(searchTerm.toLowerCase())).map((item, index) => (
                                    <ul key={index} className="main_listing custom_lisitng">
                                        <li>
                                            <h4 onClick={() => handleTaskClick(item)}>{item.name}</h4>
                                        </li>
                                        {/* <li className='status'>
                                            <div className="date">{item.start_date ? DateFormatWithSlash(item.start_date) : 'No date added'}</div>
                                        </li>
                                        <li className='status'>
                                            <div className="date">{item.end_date ? DateFormatWithSlash(item.end_date) : 'No date added'}</div>
                                        </li> */}

                                        <li className='status'>
                                        <div className="date">{item.timeline} weeks</div>
                                        </li>

                                        {/* <li className='status'>
                                            {role === 'client' && item.status === 0 && (
                                                <div className="task_list request" onClick={() => submitRequest(item)}>
                                                    Request
                                                </div>
                                            )}
                                            {role === 'client' && item.status === 1 && (
                                                <div className="task_list review">Request In Review</div>
                                            )}
                                            {role !== 'client' && item.status === 1 && (
                                                <div className="task_list request-received">Request Received</div>
                                            )}
                                            {item.status === 2 && (
                                                <div className="task_list task-progress">Task in Progress</div>
                                            )}
                                            {item.status === 3 && (
                                                <div className="task_list success">Task Completed</div>
                                            )}
                                        </li> */}
                                        {role !== 'client' && (
                                            <li className='action'>
                                                <div className="flex gap-2 justify-content-center">
                                                    <RiEditBoxFill className='cursor-pointer' onClick={() => handleClickOpen(item, 'edit')} color="orange" size={28} />
                                                    <MdDelete className='cursor-pointer' onClick={() => handleDelete(item.id)} color="red" size={28} />
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                ))}
                            </>
                        ) : (
                            <div className='flex justify-center items-center p-4 bg-[white]'>
                                <p className='text-center text-[11px] text-[#979797]'>Project has yet no tasks</p>
                            </div>
                        )}

                        {currentTask && (
                            <DrawerGrowthBoard
                                loading={false} // Add actual loading state if needed
                                open={drawerOpen}
                                toggleDrawer={toggleDrawer}
                                task={currentTask}
                                tasks={tasks}
                            // Include other necessary props here
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className='main_style_box project_listing_accordion growth-board'>
                <ul className="main_header text-center">
                    <li> Development Stage</li>
                 
                </ul>

                <ul className="main_header active">
                    <li>Marketing Activities Phase 3</li>
                    {/* <li className='status-title'>Start Date</li> */}
                    {/* <li className='status-title'>End Date</li> */}
                    <li className='status-title'>Timeline</li>
                    {/* <li className='status-title'>Task Request</li> */}
                    {role !== 'client' &&
                        <li className='action-title'>Action</li>
                    }
                </ul>

                <div className="">
                    <div className="project_content_accordion listing_class active">
                        {phaseThirdTasks.length !== 0 ? (
                            <>
                                {phaseThirdTasks.filter(task => task.name.toLowerCase().includes(searchTerm.toLowerCase())).map((item, index) => (
                                    <ul key={index} className="main_listing custom_lisitng">
                                        <li>
                                            <h4 onClick={() => handleTaskClick(item)}>{item.name}</h4>
                                        </li>
                                        {/* <li className='status'>
                                            <div className="date">{item.start_date ? DateFormatWithSlash(item.start_date) : 'No date added'}</div>
                                        </li>
                                        <li className='status'>
                                            <div className="date">{item.end_date ? DateFormatWithSlash(item.end_date) : 'No date added'}</div>
                                        </li> */}

                                        <li className='status'>
                                            <div className="date">{item.timeline} weeks</div>
                                        </li>

                                        {/* <li className='status'>
                                            {role === 'client' && item.status === 0 && (
                                                <div className="task_list request" onClick={() => submitRequest(item)}>
                                                    Request
                                                </div>
                                            )}
                                            {role === 'client' && item.status === 1 && (
                                                <div className="task_list review">Request In Review</div>
                                            )}
                                            {role !== 'client' && item.status === 1 && (
                                                <div className="task_list request-received">Request Received</div>
                                            )}
                                            {item.status === 2 && (
                                                <div className="task_list task-progress">Task in Progress</div>
                                            )}
                                            {item.status === 3 && (
                                                <div className="task_list success">Task Completed</div>
                                            )}
                                        </li> */}
                                        {role !== 'client' && (
                                            <li className='action'>
                                                <div className="flex gap-2 justify-content-center">
                                                    <RiEditBoxFill className='cursor-pointer' onClick={() => handleClickOpen(item, 'edit')} color="orange" size={28} />
                                                    <MdDelete className='cursor-pointer' onClick={() => handleDelete(item.id)} color="red" size={28} />
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                ))}
                            </>
                        ) : (
                            <div className='flex justify-center items-center p-4 bg-[white]'>
                                <p className='text-center text-[11px] text-[#979797]'>Project has yet no tasks</p>
                            </div>
                        )}

                        {currentTask && (
                            <DrawerGrowthBoard
                                loading={false} // Add actual loading state if needed
                                open={drawerOpen}
                                toggleDrawer={toggleDrawer}
                                task={currentTask}
                                tasks={tasks}
                            // Include other necessary props here
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className='main_style_box project_listing_accordion growth-board'>
                <ul className="main_header text-center">
                    <li> UAT/Launch Stage</li>
                 
                </ul>

                <ul className="main_header active">
                    <li>Marketing Activities Phase 4</li>
                    {/* <li className='status-title'>Start Date</li> */}
                    {/* <li className='status-title'>End Date</li> */}
                    <li className='status-title'>Timeline</li>
                    {/* <li className='status-title'>Task Request</li> */}
                    {role !== 'client' &&
                        <li className='action-title'>Action</li>
                    }
                </ul>

                <div className="">
                    <div className="project_content_accordion listing_class active">
                        {phaseFourthTasks.length !== 0 ? (
                            <>
                                {phaseFourthTasks.filter(task => task.name.toLowerCase().includes(searchTerm.toLowerCase())).map((item, index) => (
                                    <ul key={index} className="main_listing custom_lisitng">
                                        <li>
                                            <h4 onClick={() => handleTaskClick(item)}>{item.name}</h4>
                                        </li>
                                        {/* <li className='status'>
                                            <div className="date">{item.start_date ? DateFormatWithSlash(item.start_date) : 'No date added'}</div>
                                        </li>
                                        <li className='status'>
                                            <div className="date">{item.end_date ? DateFormatWithSlash(item.end_date) : 'No date added'}</div>
                                        </li> */}

                                        <li className='status'>
                                            <div className="date">{item.timeline} week(s)</div>
                                        </li>

                                        {/* <li className='status'>
                                            {role === 'client' && item.status === 0 && (
                                                <div className="task_list request" onClick={() => submitRequest(item)}>
                                                    Request
                                                </div>
                                            )}
                                            {role === 'client' && item.status === 1 && (
                                                <div className="task_list review">Request In Review</div>
                                            )}
                                            {role !== 'client' && item.status === 1 && (
                                                <div className="task_list request-received">Request Received</div>
                                            )}
                                            {item.status === 2 && (
                                                <div className="task_list task-progress">Task in Progress</div>
                                            )}
                                            {item.status === 3 && (
                                                <div className="task_list success">Task Completed</div>
                                            )}
                                        </li> */}
                                        {role !== 'client' && (
                                            <li className='action'>
                                                <div className="flex gap-2 justify-content-center">
                                                    <RiEditBoxFill className='cursor-pointer' onClick={() => handleClickOpen(item, 'edit')} color="orange" size={28} />
                                                    <MdDelete className='cursor-pointer' onClick={() => handleDelete(item.id)} color="red" size={28} />
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                ))}
                            </>
                        ) : (
                            <div className='flex justify-center items-center p-4 bg-[white]'>
                                <p className='text-center text-[11px] text-[#979797]'>Project has yet no tasks</p>
                            </div>
                        )}

                        {/* {currentTask && (
                            <DrawerGrowthBoard
                                loading={false} // Add actual loading state if needed
                                open={drawerOpen}
                                toggleDrawer={toggleDrawer}
                                task={currentTask}
                                tasks={tasks}
                            // Include other necessary props here
                            />
                        )} */}
                    </div>
                </div>
            </div>

           

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    {mode === 'create' ? "Create A Task" : "Update Task"}
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={formik.handleSubmit} className='space-y-6 '>
                        <TextField
                            className='w-[550px] tab:w-[100%] mobile:w-[100%] small:w-[100%] '
                            id="name"
                            name="name"
                            label="Task Name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        {/* <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <FormControl className="custom-input" fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                                        <DatePicker
                                            fullWidth
                                            className='w-[100%]'
                                            name="start_date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="start_date"
                                            inputFormat="E MMM dd yyyy HH:MM:SS O"
                                            label="Start Date"
                                            value={formik.values.start_date}
                                            onChange={value => formik.setFieldValue('start_date', value)}
                                            error={formik.touched.start_date && Boolean(formik.errors.start_date)}


                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                                {formik.touched.start_date && formik.errors.start_date && (
                                    <div className='errorform'>{formik.errors.start_date}</div>
                                )}
                            </FormControl>
                            <FormControl className="custom-input" fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                                        <DatePicker
                                            fullWidth
                                            className='w-[100%]'
                                            name="end_date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="end_date"
                                            inputFormat="E MMM dd yyyy HH:MM:SS O"
                                            label="End Date"
                                            value={formik.values.end_date}
                                            onChange={value => formik.setFieldValue('end_date', value)}
                                            error={formik.touched.end_date && Boolean(formik.errors.end_date)}


                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                                {formik.touched.end_date && formik.errors.end_date && (
                                    <div className='errorform'>{formik.errors.end_date}</div>
                                )}
                            </FormControl>
                        </div> */}

                        <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Task Timeline</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    name="timeline"
                                    label="Task Timeline"
                                    id="timeline"
                                    value={formik.values.timeline}
                                    onChange={formik.handleChange}
                                    error={formik.touched.timeline && Boolean(formik.errors.timeline)}
                                    helperText={formik.touched.timeline && formik.errors.timeline}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    <MenuItem value={1}>1 Week</MenuItem>
                                    <MenuItem value={2}>2 Weeks</MenuItem>
                                    <MenuItem value={3}>3 Weeks</MenuItem>
                                    <MenuItem value={4}>4 Weeks</MenuItem>
                                    <MenuItem value={5}>5 Weeks</MenuItem>
                                    <MenuItem value={6}>6 Weeks</MenuItem>
                                    <MenuItem value={7}>7 Weeks</MenuItem>
                                    <MenuItem value={8}>8 Weeks</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <FormControl fullWidth>
                                <InputLabel id="marketing_phase_id">Marketing Phase</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="marketing_phase_id"
                                    name="marketing_phase_id"
                                    label="Marketing Phase"
                                    id="marketing_phase_id"
                                    value={formik.values.marketing_phase_id}
                                    onChange={formik.handleChange}
                                    error={formik.touched.marketing_phase_id && Boolean(formik.errors.marketing_phase_id)}
                                    helperText={formik.touched.marketing_phase_id && formik.errors.marketing_phase_id}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    {marketingPhases?.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={`${item.id}`}>{item.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </div>

                        {/* <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            {mode !== 'create' &&
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Task Status</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        name="status"
                                        label="Task Status"
                                        id="status"
                                        value={formik.values.status}
                                        onChange={formik.handleChange}
                                        error={formik.touched.status && Boolean(formik.errors.status)}
                                        helperText={formik.touched.status && formik.errors.status}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        <MenuItem value={0}>Reject</MenuItem>
                                        <MenuItem value={2}>Approve</MenuItem>
                                        <MenuItem value={3}>Complete</MenuItem>
                                    </Select>
                                </FormControl>
                            }
                        </div> */}

                        <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <TextField
                                className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                id="desc"
                                name="desc"
                                label="Description"
                                value={formik.values.desc}
                                onChange={formik.handleChange}
                                error={formik.touched.desc && Boolean(formik.errors.desc)}
                                helperText={formik.touched.desc && formik.errors.desc}
                            />
                        </div>

                        <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                            <div className="file-upload-container">
                                <input type="file"  onChange={handleFileInput} ref={fileInputRef} />
                            </div>
                        </div>

                        { editValues?.file_path &&
                            <div className="file_link">
                                <a href={editValues?.file_path} target="_blank">Preview Uploaded file <IoEyeOutline size={20} /></a>
                            </div>
                        }

                        <div className='flex justify-end mt-3'>
                            <button className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-3 text-white'
                                variant="contained" fullWidth type="submit">
                                {btnLoader ? <Oval
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#ffffff"
                                    secondaryColor="#ffffff"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{ disply: 'flex', justifyContent: 'center' }}
                                    wrapperClass=""
                                /> : 'Save'}
                            </button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </>

    )
}

export default TaskTable