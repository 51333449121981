import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { NavLink, Link } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import InvoiceList from "./InvoiceList";
import TekPoints from "../../img/Loyalty.svg";
import InvoiceProject from "./components/InvoiceProject";
import { Table } from "react-bootstrap";
import { useStateContext } from "../../contexts/ContextProvider";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios'
import { DateFormatWithSlash, DateAndTimestamp, DateFormatWithLetters } from '../../utils/DateFormatWithSlash';
import { Oval, ThreeDots } from 'react-loader-spinner'
import UserRewards from './components/UserRewards';
import InvoiceHistory from "./components/InvoiceHistory";
import { intToString } from '../../utils/PriceToString';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    TextField,
    MenuItem,
    InputLabel,
    FormControl,
    Select,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel
} from '@material-ui/core';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Slide from '@mui/material/Slide';
import Swal from 'sweetalert2';
import { S3Upload } from '../../utils/S3Upload';
import dayjs from 'dayjs';

import { Toast } from "../../components/General";
import Form from 'react-bootstrap/Form';
import { partial } from "lodash";


window.Buffer = window.Buffer || require("buffer").Buffer;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+)(:[0-9]{1,5})?(\/.*)?$/;

const validationSchema = yup.object({
    // client: yup
    //     .string()
    //     .required('Client Name is required'),
    // project: yup
    //     .string()
    //     .required('Project Name is required'),
    // project_type: yup
    //     .string()
    //     .required('Project Type is required'),
    client_id: yup
        .number()
        .required('Client is required'),

    project_phase_id: yup
        .number()
        .required('Project Phase is required'),

    amount: yup
        .string()
        .required('Amount is required'),

    status: yup
        .string()
        .required('Status is required'),

    due_date: yup.date().required('Due date is required'),
    file_path: yup.string().notRequired(),

    link: yup
        .string()
        .url('Invalid URL format')
        .matches(urlRegex, 'Please enter a valid URL')
        .nullable('URL is required'),

           
    invoice_type_id: yup.string().required('Invoice Type is required'),


    currency_id: yup
    .number()
    .required('Currency is required'),
    
    // file: yup.mixed().when('link', {
    //     is: (link) => !link || link.length === 0,
    //     then: yup.mixed().required('Either a link or a file is required'),
    //     otherwise: yup.mixed().nullable(),
    // }),

    // link: yup.string().when('file', {
    //     is: (file) => !file,
    //     then: yup.string().required('Either a link or a file is required').url('Must be a valid URL'),
    //     otherwise: yup.string().nullable().url('Must be a valid URL'),
    // }),

    // pay_date: yup
    //     .date().required('Pay Date is required'),
    // link: yup
    //   .string()
    //   .required('Invoice Link is required'),
});

function InvoiceVeiw(props) {

    const { invoices, user, setInvoices, getAllInvoice, client, setClient, table, manualProjects, project, selectedProject } = useStateContext();
    const { role } = user.user;
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const params = useParams();
    const [open, setOpen] = React.useState(false);
    const [projectState, setProjectState] = useState('manual');
    const [projectType, setProjectType] = useState([]);
    const navigate = useNavigate();
    const [currentdate, setCurrentdate] = useState(new Date());
    const [next, setNext] = useState(null);
    const [previous, setPrevious] = useState(null);
    const [data, setData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(false);
    const [users, setUsers] = useState([]);
    const fileInputRef = useRef(null);
    const [btnLoader, setBtnLoader] = useState(false);
    const [projectPhase, setProjectPhase] = useState([]);
    const [selected, setSelected] = useState(false);
    const [partialAmountError, setPartialAmountError] = useState(false);
    const [partialAmountErrorMessage, setPartialAmountErrorMessage] = useState("");
    const [currencies, setCurrencies] = useState([]);


    const Refresher = useCallback(() => {

        console.log("Refresher");

        fetchAllInvoice();
    }, [])

    const UpdateReadStatus = async () => {

        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}update-invoice-status/${user.user.id}`, { is_read: 1 }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        setLoading(false);
                    }
                })
                .catch((error) => {
                })
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };


    const getAllInvoiceByPid = () => {
        if (user) {
            let url = `${process.env.REACT_APP_BASE_URL}invoice?manual_project_id=${selectedProject}`
            axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            }).then((response) => {
                if (response && response.data) {


                    const formattedData = response?.data?.data?.invoices?.map(item => {
                        const due_date = new Date(item.due_date);
                        const pay_date = item.pay_date ? new Date(item.pay_date) : null;
                        const formattedDueDate = due_date.toLocaleDateString();
                        const formattedPayDate = pay_date.toLocaleDateString() ?? null;
                        const nextInvoice = item.nextInvoice;
                        const previousInvoice = item.previousInvoice;
                        const paid_invoice_sum = item.paid_invoice_sum;
                        const unpaid_invoice_sum = item.unpaid_invoice_sum;
                        return {
                            ...item,
                            due_date: formattedDueDate,
                            pay_date: formattedPayDate,
                            nextInvoice,
                            previousInvoice,
                            paid_invoice_sum,
                            unpaid_invoice_sum
                        };
                    });

                    setInvoices(formattedData)
                }
            }).catch((err) => {
                console.log('err====', err);
            })
        }
    }


    const getAllCurrencies = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}get_currencies`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {

            setCurrencies(response.data.data);

        }).catch((err) => {
            console.error('error retrieving currency', err);
        })
    }

    const getAllUsers = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}get_clients_by_user/${user.user.id}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {

            setClient(response.data.data);

        }).catch((err) => {
            console.error('error retrieving client', err);
        })
    }

    const getProjectPhase = async () => {
        await axios.get(`${process.env.REACT_APP_BASE_URL}project-phase`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            setProjectPhase(response.data.data);
        }).catch((err) => {
            console.error('error retrieving client', err);
        })
    }

    function removeCommaAndConvertToFloat(numberString) {
        // Remove commas and convert to float
        if (numberString == null) return NaN;

        // Ensure numberString is a string
        numberString = String(numberString);

        // Remove commas and convert to float
        return parseFloat(numberString.replace(/,/g, ''));
    }

    function formatNumberWithCommas(numberString) {
        // Convert the string to a number
        const number = parseFloat(removeCommaAndConvertToFloat(numberString));

        // Check if it's a valid number
        if (isNaN(number)) {
            return numberString; // Return original if not a valid number
        }

        return (number.toLocaleString());
    }

    useEffect(() => {
        // setLoading(true);
        // let url = `${process.env.REACT_APP_BASE_URL}invoice?manual_project_id=${selectedProject}`;
        // (async () => {
        //     let response = await axios.get(url, {
        //         headers: {
        //             'Authorization': `Bearer ${user.token}`
        //         }
        //     });

        //     if (response && response.data) {
        //         const invoiceData = response?.data?.data?.invoices?.map(item => {


        //             const due_date = new Date(item.due_date);
        //             const pay_date = new Date(item.pay_date) ?? null;
        //             const formattedDueDate = due_date.toLocaleDateString();
        //             const formattedPayDate = item.pay_date !== null && item.pay_date !== '' ? pay_date.toLocaleDateString() : '';

        //             return {
        //                 ...item,
        //                 due_date: formattedDueDate,
        //                 pay_date: formattedPayDate,
        //             };
        //         });
        //         setInvoices(invoiceData)

        //         const formattedData = {
        //             'nextInvoice': response?.data?.data?.nextInvoice,
        //             'previousInvoice': response?.data?.data?.previousInvoice,
        //             'paid_invoice_sum': response?.data?.data?.paid_invoice_sum,
        //             'unpaid_invoice_sum': response?.data?.data?.unpaid_invoice_sum,
        //         }
        //         setData(formattedData)
        //         setLoading(false);
        //     }
        // })();

        fetchAllInvoice();

    }, [selectedProject])

    const fetchAllInvoice = () => {
        // setLoading(true);

        axios.get(`${process.env.REACT_APP_BASE_URL}invoice?manual_project_id=${selectedProject}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {

            const invoiceData = response?.data?.data?.invoices?.map(item => {


                const due_date = new Date(item.due_date);
                const pay_date = new Date(item.pay_date) ?? null;
                const formattedDueDate = due_date.toLocaleDateString();
                const formattedPayDate = item.pay_date !== null && item.pay_date !== '' ? pay_date.toLocaleDateString() : '';

                return {
                    ...item,
                    due_date: formattedDueDate,
                    pay_date: formattedPayDate,
                };
            });

           
            setInvoices(invoiceData);

            const formattedData = {
                'nextInvoice': response?.data?.data?.nextInvoice,
                'previousInvoice': response?.data?.data?.previousInvoice,
                'paid_invoice_sum': response?.data?.data?.paid_invoice_sum,
                'unpaid_invoice_sum': response?.data?.data?.unpaid_invoice_sum,
            }

            setData(formattedData);

        }).catch((err) => {
            console.error('error retrieving client', err);
        }).finally((err) => {
            // setLoading(false);
        })
    }

    useEffect(() => {
        UpdateReadStatus();
        getAllUsers();
        getProjectPhase();
        getAllCurrencies();
    }, [])


    useEffect(() => {
        //getNextMonthInvoices(currentdate)
        //getPreviousMonthInvoices(currentdate)
    }, [invoices])

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

  
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        formik.resetForm();
        setSelectedFile(false);
        if (fileInputRef?.current?.value) {
            fileInputRef.current.value = null;
        }

        setOpen(false);
    };

    const filteredInvoices = invoices.reduce((uniqueItems, item) => {
        const existingItem = uniqueItems.find(uniqueItem => {
            if (item.manual_project && uniqueItem.manual_project) {
                return uniqueItem.manual_project.name === item.manual_project.name;
            } else {
                const selectedProject = table.find(asana => `${asana.gid}` === item.asana_project_id);
                return selectedProject?.gid === item.asana_project_id;
            }
        });

        if (!existingItem) {
            uniqueItems.push(item);
        }
        return uniqueItems;
    }, []);


    const getAllProjectUsers = () => {
        setLoading(true);
        // axios.get(`${process.env.REACT_APP_BASE_URL}get_clients_by_project/${selectedProject}`, {
        // axios.get(`${process.env.REACT_APP_BASE_URL}get_clients_by_user/${user.user.id}`,{
        axios.get(`${process.env.REACT_APP_BASE_URL}get_clients_by_project/${selectedProject}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            setUsers(response.data.data);
            setLoading(false);
        }).catch((err) => {
            console.log('err====', err);
        })
        // console.log(table);
    }

    useEffect(() => {
        getAllProjectUsers();
    }, [selectedProject]);


    const createInvoice = async (file_uri = "", resetForm) => {
        setBtnLoader(true);

        let date = formik?.values?.due_date ? new Date(formik.values.due_date)?.toUTCString() : null;
        let due_dates = formik.values.due_date;

        const data = {
            "client_id": formik?.values?.client_id,
            "due_date": date,
            "due_dates": due_dates,
            "pay_date": null,
            "manual_project_id": parseInt(selectedProject), //projectState === 'manual' ? selectedProject.id : null,
            "asana_project_id": '',//projectState === 'asana' ? selectedProject.gid : null,
            "status": formik.values.status,
            "link": formik.values.link,
            "user_id": parseInt(user.user.id),
            "amount": removeCommaAndConvertToFloat(formik.values.amount),
            "project_phase_id": formik.values.project_phase_id,
            "invoice_type_id": formik.values.invoice_type_id,
            "partial_amount": formik.values.invoice_type_id == 1 ? removeCommaAndConvertToFloat(formik.values.partial_amount) : null,
            "remaining_amount": formik.values.invoice_type_id == 1 ? removeCommaAndConvertToFloat(formik.values.remaining_amount) : null,
            "is_completed": formik.values.invoice_type_id == 1 ? 0 : 1,
            "currency_id": formik.values.currency_id,
        }

        console.log(data);

        if (file_uri) {
            data['file_path'] = file_uri;
        }

        await axios.post(`${process.env.REACT_APP_BASE_URL}invoice`, { ...data }, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((res) => {
            if (res.status === 200) {
          
                resetForm({ values: "" });
                handleClose();
                fetchAllInvoice();
                
                Toast.fire({
                    icon: 'success',
                    title: 'Invoice Created successfully!',
                })

                setSelected(!selected);
            }
        })
        .catch((error) => {
   
            resetForm({ values: "" });
            handleClose();

            Toast.fire({
                icon: 'error',
                title: 'Something went wrong!',
            })
        })
        .finally(() => {
            setBtnLoader(false);
        });
    };

    const handleUpload = async (file, resetForm) => {
        setBtnLoader(true);
        // console.log(selectedFile);

        if (selectedFile) {
            const response = await S3Upload(file.type, file.name, file);

            if (response) {
                createInvoice(response, resetForm);
            }

        }
        else {
            createInvoice('', resetForm);
        }
    }

    const handlePartialAmountOnchange =  (e) => {

        let totalAmount     = removeCommaAndConvertToFloat(formik.values.amount);
        let partialAmount   = removeCommaAndConvertToFloat(e.target.value);
        let remainingAmount = (totalAmount) -  (partialAmount);

        formik.setValues(prevValues => ({
            ...prevValues,
            remaining_amount: isNaN(remainingAmount) ? 0 : formatNumberWithCommas(remainingAmount),
        }));

        if(isNegative(remainingAmount)) {

            setPartialAmountError(true);
            setPartialAmountErrorMessage("Please Enter Valid Partial Amount");
        }
        else{
            setPartialAmountError(false);
            setPartialAmountErrorMessage("");
        }

        // formik.handleChange(e);
        formik.setFieldValue('partial_amount',  formatNumberWithCommas(partialAmount))
    }

    
    function isNegative(number) {
        return number < 0;
    }


    const formik = useFormik({
        initialValues: {
            client_id: '',
            project: null,
            //project_type: null,
            amount: '',
            //project_type: '',
            due_date: null,
            pay_date: null,
            status: '',
            link: '',
            file_path: '',
            project_phase_id: '',
            invoice_type_id: 2,
            partial_amount: null,
            remaining_amount: null,
            currency_id: null,
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {

            handleUpload(selectedFile || [], resetForm);
            // createInvoice();
            // // console.log(values);




            // resetForm({
            //     values: {
            //         client_id: '',
            //         project: null,
            //         amount: null,
            //         due_date: null,
            //         pay_date: null,
            //         status: '',
            //         link: ''
            //     }
            // });
        },
    });


    return (
        <>
            <div className="main_top_title">
                <div className="main_title">
                    <h1>Invoice <span>Board</span></h1>
                </div>
                <div className="main_btn_txt">
                    <ul>
                        <li>
                            <NavLink to="">
                                Dashboard
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="">
                                Appboard
                            </NavLink>
                        </li>
                        <li className="active">
                            <NavLink to="">
                                Invoice Board
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="main-top-banner">

                {role !== "client" ? (
                    <div className="main-btn-txt">
                        <button className="btn-banner" onClick={handleClickOpen}>Create New Invoice</button>
                    </div>
                ) : null}
            </div>

            <div className="main_content_container">
                <Container fluid className="p-0">
                    <Row>
                        <Col xxl={role === 'client' ? 9 : 12}>
                            {/* <InvoiceProject /> */}


                            {/* <div className="main_style_box">
                        <Col xl={12} xxl={role === 'client' ? 9 : 12}>
                            <div className="main_style_box">
                                <div className="project_title">Project Information</div>
                                <div className="downloadable_content">
                                    <div className="meeting_tabs invoice_Info m-3">
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Project Name</th>
                                                    <th className="text-center">Next Deadline</th>
                                                    <th className="text-center">Current Phase</th>
                                                    <th className="text-center">Project Category</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredInvoices.length !== 0 ? (
                                                    filteredInvoices.map((item, index) => {
                                                        let project_name;
                                                        if (item.manual_project) {
                                                            project_name = item.manual_project.name;
                                                        } else {
                                                            const selectedProject = table.find(asana => `${asana.gid}` === item.asana_project_id);
                                                            project_name = selectedProject ? selectedProject.name : '';
                                                        }

                                                        return project_name && (
                                                            <tr key={index} className="border_0">
                                                                <td className="text-center">
                                                                    <p className="font-medium">{project_name}</p>
                                                                </td>
                                                                <td className="text-center">
                                                                    <p className="font-medium">{DateFormatWithLetters(item.due_date)}</p>
                                                                </td>
                                                                <td className="text-center">
                                                                    <p className="break-all font-medium pl-[2px]">
                                                                        {item.manual_project_id !== null ? item.manual_project?.phase?.name : 'Alpha Development'}
                                                                    </p>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="Design p-2 rounded">
                                                                        {item.manual_project_id !== null ? item.manual_project?.category?.name : 'Development'}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td colSpan="4" className="text-center">No projects with invoices</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div> */}

                            <div className="main_style_box">
                                <div className="project_title">Invoice History</div>
                                <InvoiceHistory pid={params.pid} refresher={Refresher} data={invoices} loader={loading} projectPhase={projectPhase} users={users} recallData={selected} />
                            </div>
                        </Col>


                        {role == 'client' ? (
                            <Col xl={12} xxl={3}>
                                <div className="side_content">
                                    <div className="rewads_section">
                                        <UserRewards />
                                    </div>
                                    <div className="invoices_listing">
                                        <div className="reward_box_listing">
                                            <div className="numbers">${data?.nextInvoice?.amount || 0}</div>
                                            <div class="txt_list">Next Invoice <span>Due Date:{data?.nextInvoice?.due_date ? new Date(data.nextInvoice.due_date).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit'
                                            }) : 'No Date'}</span></div>
                                        </div>
                                        <div className="reward_box_listing">
                                            <div className="numbers">${data?.previousInvoice?.amount || 0}</div>
                                            <div class="txt_list">Previous Invoice <span>Pay Date: {data?.previousInvoice?.pay_date ? new Date(data.previousInvoice.pay_date).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit'
                                            }) : 'No Date'}</span></div>
                                        </div>
                                        <div className="reward_box_listing">
                                            <div className="numbers">${intToString(data?.paid_invoice_sum || 0)}</div>
                                            <div class="txt_list">Paid Invoice</div>
                                        </div>
                                        <div className="reward_box_listing">
                                            <div className="numbers">${intToString(data?.unpaid_invoice_sum || 0)}</div>
                                            <div class="txt_list">Remaining Invoice</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ) : null

                        }
                    </Row>
                </Container>

                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Create New Invoice"}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={formik.handleSubmit} className='space-y-6 '>

                            <div className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                                <FormControl fullWidth error={formik.touched.client && Boolean(formik.errors.client)}>
                                    <InputLabel id="client_id">Client</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="client_id"
                                        name="client_id"
                                        label="Client"
                                        id="client_id"
                                        value={formik.values.client_id} // Ensure it's a single value
                                        helperText={formik.touched.client_id && formik.errors.client_id}
                                        onChange={(event) => {
                                            formik.setFieldValue('client_id', event.target.value);
                                        }}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                    >
                                        {users?.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>
                                                {item.first_name} {item.last_name}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                    {formik.touched.client_id && <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.client_id}</div>}
                                </FormControl>
                            </div>

                            <div className="flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8">
                                <FormControl fullWidth error={formik.touched.project_phase_id && Boolean(formik.errors.project_phase_id)}>
                                    <InputLabel id="project_phase_id">Project Phase</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="project_phase_id"
                                        name="project_phase_id"
                                        label="project_phase_id"
                                        id="project_phase_id"
                                        value={formik.values.project_phase_id} // Ensure it's a single value
                                        helperText={formik.touched.project_phase_id && formik.errors.project_phase_id}
                                        onChange={(event) => {
                                            formik.setFieldValue('project_phase_id', event.target.value);
                                        }}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                    >
                                        {projectPhase.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                    {formik.touched.project_phase_id && <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.project_phase_id}</div>}
                                </FormControl>
                            </div>

                            <div
                                className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>

                                {/* <FormControl className="custom-input" fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                                            <DatePicker
                                                fullWidth
                                                className='w-[100%]'
                                                name="pay_date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                id="pay_date"
                                                inputFormat="E MMM dd yyyy HH:MM:SS O"
                                                label="Pay Date"
                                                value={formik.values.pay_date}
                                                onChange={value => formik.setFieldValue('pay_date', value)}
                                                error={formik.touched.pay_date && Boolean(formik.errors.pay_date)}


                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </FormControl> */}


                                <FormControl className="custom-input" fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                                fullWidth
                                                className='w-[100%]'
                                                name="due_date"
                                                id="due_date"
                                                inputFormat="MMM dd yyyy"
                                                label="Due Date"
                                                value={formik.values.due_date}
                                                // onChange={value => {
                                                //         // formik.setFieldValue('due_date', value);
                                                //         console.log(value);
                                                //     }
                                                // }
                                                onChange={value => formik.setFieldValue('due_date',  dayjs(value).format("YYYY-MM-DD"))}
                                                // onChange={value => formik.setFieldValue('due_date', dayjs(value).format("YYYY-MM-DD"))}
                                                minDate={dayjs()}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>

                                    {formik.touched.due_date && <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.due_date}</div>}
                                </FormControl>
                            </div>


                            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                <FormControl fullWidth>
                                    <InputLabel id="payment-select-label">Invoice Type</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="payment-select-label"
                                        name="invoice_type_id"
                                        label="Payment Type"
                                        id="invoice_type_id"
                                        value={formik.values.invoice_type_id}
                                        onChange={formik.handleChange}
                                        error={formik.touched.invoice_type_id && Boolean(formik.errors.invoice_type_id)}
                                        helperText={formik.touched.invoice_type_id && formik.errors.invoice_type_id}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        <MenuItem value={1}>Partial</MenuItem>
                                        <MenuItem value={2}>Full</MenuItem>
                                    </Select>

                                    {formik.touched.invoice_type_id && <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.invoice_type_id}</div>}
                                </FormControl>


                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        name="status"
                                        label="Status"
                                        id="status"
                                        value={formik.values.status}
                                        onChange={formik.handleChange}
                                        error={formik.touched.status && Boolean(formik.errors.status)}
                                        helperText={formik.touched.status && formik.errors.status}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        <MenuItem value={1}>Paid</MenuItem>
                                        <MenuItem value={0}>Unpaid</MenuItem>
                                    </Select>

                                    {formik.touched.status && <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.status}</div>}
                                </FormControl>
                            </div>

                            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        name="currency_id"
                                        label="currency_id"
                                        id="currency_id"
                                        value={formik.values.currency_id}
                                        onChange={formik.handleChange}
                                        error={formik.touched.currency_id && Boolean(formik.errors.currency_id)}
                                        helperText={formik.touched.currency_id && formik.errors.currency_id}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >

                                        {currencies.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>
                                                {item.currency} {item.code}
                                            </MenuItem>
                                        ))}

                                    </Select>

                                    {formik.touched.currency_id && <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.currency_id}</div>}
                                </FormControl>


                                <TextField
                                    className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                    type="text"
                                    id="amount"
                                    name="amount"
                                    label="Amount"
                                    value={formik.values.amount}
                                    onChange={event => (
                                        formik.setFieldValue('amount',  formatNumberWithCommas(event.target.value))
                                    )}
                                    error={formik.touched.amount && Boolean(formik.errors.amount)}
                                    helperText={formik.touched.amount && formik.errors.amount}
                                />
                            </div>

                            {formik.values.invoice_type_id == 1 && 
                                <>
                                <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                    <TextField
                                        className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                        type="text"
                                        id="partial_amount"
                                        name="partial_amount"
                                        label="Partial Amount"
                                        value={formik.values.partial_amount}
                                        onChange={handlePartialAmountOnchange}
                                        error={formik.touched.partial_amount && Boolean(formik.errors.partial_amount)}
                                        helperText={formik.touched.partial_amount && formik.errors.partial_amount}
                                    />

                                    
                                </div>

                                    <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                        {partialAmountError && <div style={{ color: 'red', marginTop: '7px' }}>{partialAmountErrorMessage}</div>}
                                    </div>

                                    <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>

                                        <TextField
                                            className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                            type="text"
                                            id="remaining_amount"
                                            name="remaining_amount"
                                            label="Remaining Amount"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            value={formik.values.remaining_amount}
                                            onChange={formik.handleChange}
                                            error={formik.touched.remaining_amount && Boolean(formik.errors.remaining_amount)}
                                            helperText={formik.touched.remaining_amount && formik.errors.remaining_amount}
                                        />
                                    </div>
                                </>
                            }
                       

                            <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Attachment Type</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        name="attachment"
                                        label="Attachment Type"
                                        id="attachment"
                                        value={formik.values.attachment}
                                        onChange={formik.handleChange}
                                        error={formik.touched.attachment && Boolean(formik.errors.attachment)}
                                        helperText={formik.touched.attachment && formik.errors.attachment}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        <MenuItem value={1}>Link</MenuItem>
                                        <MenuItem value={0}>File</MenuItem>
                                    </Select>

                                    {formik.touched.attachment && <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.attachment}</div>}
                                </FormControl>
                            </div>
                            {formik.values.attachment == 1 &&
                                <div
                                    className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                    <TextField
                                        className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                        id="link"
                                        name="link"
                                        label="Invoice Link"
                                        value={formik.values.link}
                                        onChange={formik.handleChange}
                                        error={formik.touched.link && Boolean(formik.errors.link)}
                                        helperText={formik.touched.link && formik.errors.link}
                                    />
                                </div>

                            }

                            {formik.values.attachment == 0 &&
                                <div className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                    <div className="file-upload-container">
                                        <input type="file" name="file" ref={fileInputRef} onChange={handleFileInput} />
                                    </div>

                                    {formik.touched.file && <div style={{ color: 'red', marginTop: '7px' }}>{formik.errors.file}</div>}
                                </div>
                            }




                            <div className='flex justify-end mt-3'>
                                <button className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-3 text-white'
                                    variant="contained" fullWidth type={ partialAmountError ? 'button' : 'submit'} disabled={btnLoader}>
                                    {btnLoader ? <Oval
                                        visible={true}
                                        height="20"
                                        width="20"
                                        color="#ffffff"
                                        secondaryColor="#ffffff"
                                        ariaLabel="oval-loading"
                                        wrapperStyle={{ disply: 'flex', justifyContent: 'center' }}
                                        wrapperClass=""
                                    /> : 'Save'}
                                </button>
                            </div>
                        </form>
                    </DialogContent>

                </Dialog>


            </div>
        </>
    );
}

export default InvoiceVeiw;