
import React, { useEffect, useState, useLayoutEffect } from "react";
import Navbar from "./Navbar";
import Footer from './Footer'
import Sidebar from "./Sidebar";
import { NavLink, useLocation } from "react-router-dom";
import { useStateContext } from '../contexts/ContextProvider';
import Form from 'react-bootstrap/Form';
import { getFirstLetters, getRandomColor } from '../utils/Helper'
import {
    DashboardIcon,
    DownloadableBoard,
    InvoiceBoard,
    MeetingBoard,
    ProjectBoard,
    ReferralsBoard,
    RewardsBoard,
    SettingBoard,
    MarketingBoard,
    TekRevolLogo,
    SearchIcon, CommentsIcon, BellIcon,
    OverviewIcon,
    FilesIcon,
    ListIcon,
    BoardIcon,
    CalendarIcon,
    StatusIcon, MemberIcon, LogoutIcon,
    UserIcon,
    DotValueIcon
} from "../app-constants";
import WhiteLogoCRM from '../data/white-logo-crm.svg'
import DumImg from "../img/dummy_profile.png";
import OverviewBoarc from "../pages/new/Overview";
import { FaCircle } from 'react-icons/fa';

import { MenuItem, InputLabel, FormControl, Select } from '@material-ui/core';

import axiosInstance from '../../src/axios/axiosInstance';
import axios from "axios";

import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import AddButtonShortcut from "../AddButtonShortcut";
import {Logo} from "../utils/svg";
import {LogoWhite} from "../utils/svg";
import {NewLogoForLightBg} from "../utils/svg";


const DashboardLayout = (props) => {
    // const { user, tag, currentMode, activeMenu, setActiveMenu, setDefaultProject, project, setProject, manualProjects,userprofile } = useStateContext();
    // const sessionProject = sessionStorage.getItem('session_project');
    // const location = useLocation();

    // const { user, tag, currentMode, activeMenu, setActiveMenu, setDefaultProject, project, setProject, manualProjects 
    const navigate = useNavigate();
    const [activeItem, setActiveItem] = useState(null);

    const location = useLocation();


    const excludedPaths = ["/list-view", "/marketing-list-view", "/board-view", "/marketing-board-view"];

    const {
        user,
        role,
        tag,
        // currentMode, 
        activeMenu,
        setActiveMenu,
        // setDefaultProject, 
        selectedProject,
        setSelectedProject,
        manualProjects,
        getAllManualProjects,
        userprofile,
        count,
        getNotificationCount
    } = useStateContext();

    const [setting, setSetting] = useState({ meeting: false, invoice: false });


    useEffect(() => {
        getNotificationCount();
        getUserSetting();
        // getAllManualProjects();
    }, [location.pathname]);


    const handleLogOut = () => {
        Swal.fire({
            title: "Are you sure you want to Logout?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#F37A20",
            cancelButtonColor: "#0F1328",
            confirmButtonText: "Yes, Logout!",
        }).then((result) => {
            if (result.isConfirmed) {
                logout();
            }
        });
    };

    const logout = () => {
        console.log("hello");
        try {
            axios
                .get(`${process.env.REACT_APP_BASE_URL}user_logout`, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        localStorage.removeItem("tekrevol_user");
                        localStorage.removeItem("access_token");
                        localStorage.removeItem("access_code");
                        // setTable([]);
                        // setManualProjects([]);
                        sessionStorage.removeItem('session_project')
                        setTimeout(() => {
                            navigate("/");
                        }, 500);
                    }
                })
                .catch((error) => {
                    localStorage.removeItem("tekrevol_user");
                    localStorage.removeItem("access_token");
                    localStorage.removeItem("access_code");
                    // setTable([])
                    // setManualProjects([])
                    setTimeout(() => {
                        navigate("/signin");
                    }, 500);
                    console.log("ERROR IN LOGOUT", error);
                });
        } catch (error) {


            console.log(error);
            // console.warn("LOGOUT ERROR", error.response.data);
        }
    };

    const getUserSetting = async () => {

        if(user) {

            await axios.get(`${process.env.REACT_APP_BASE_URL}get_user/${user.user.id}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            }).then((response) => {

                let invoice = response.data.data.invoice == 1 ? true : false;
                let meeting = response.data.data.meeting == 1 ? true : false;

    
                setSetting({ meeting: meeting, invoice: invoice });
    
            }).catch((err) => {
                console.log('err====', err);
            })
        }

    }

    // useEffect(() => {
    //     getUserSetting();
    // }, []);

    // const setDefaultManualProject = () => {

    //     if (user && !sessionProject) {

    //         console.log("setDefaultManualProject");

    //         setProject(manualProjects[0].id);
    //     }
    // }

    // useLayoutEffect(() => {
    //     setDefaultManualProject();
    // }, []);


    // const setDefaultProject = () => {

    //     const sessionProject = sessionStorage.getItem('session_project');
    //     let url;

    //     if (user && !sessionProject) {

    //         if (role?.name === 'admin') {
    //             url = `manual-project?all=1`
    //         } else if (role?.name === 'client') {
    //             url = `manual-project?project_by_client=${user.user.id}`
    //         } else {
    //             url = `manual-project?business_dev=${user.user.id}`
    //         }

    //         axiosInstance.get(url)
    //             .then((response) => {
    //                 if (response?.data?.length > 0) {
    //                     sessionStorage.setItem('session_project', response?.data[0].id);
    //                     setProject(response?.data[0].id);
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.error('Error fetching data:', error);
    //             });
    //     }
    // };


    // console.log(role.display_name);




    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        /*if(!user){
            history('/signin');
        }*/
        // setDefaultProject();



        const handleResize = () => {
            if (window.innerWidth < 1024) {
                setActiveMenu(false);
            }

            else {
                setActiveMenu(true);
            }
        };
        // handleResize(); // Check screen size on component mount
        window.addEventListener("resize", handleResize); // Check screen size on window resize
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);




    const handleClick = (item) => {
        setActiveItem(activeItem === item ? null : item);
    };

    const handleChange = (e) => {
        sessionStorage.setItem('session_project', e.target.value);
        setSelectedProject(e.target.value);
    }

    return (

        <div className="tekrevol_crm_container">
            <div className="tekrevol_crm_sidebar">
                <div className="tekrevol_crm_logo">
                    <NavLink to="/">
                        {/* {TekRevolLogo} */}
                        {/*<img src={WhiteLogoCRM} alt="Tekrevol Logo" />*/}
                        {/* <LogoWhite/> */}
                        <NewLogoForLightBg />
                    </NavLink>

                </div>
                <ul className="tekrevol_crm_navigation">
                    <li onClick={() => handleClick(null)}>
                        <NavLink to="/">
                            {DashboardIcon} Dashboard
                        </NavLink>
                    </li>
                    <li onClick={() => handleClick('app')}>
                        <a href={'#'} className={activeItem === 'app' ? 'active' : ''}>
                            {ProjectBoard} App Board
                        </a>
                    </li>
                    <div className={activeItem === 'app' ? 'submenu active' : 'submenu'}>
                        <ul>
                            <li>
                                <NavLink to="/overview-new">
                                    {OverviewIcon}Overview
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/list-view">
                                    {ListIcon}List View
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/board-view">
                                    {BoardIcon}Card View
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/calender-view">
                                    {CalendarIcon}Calendar
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/status-view">
                                    {StatusIcon}Status
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/file-view">
                                    {FilesIcon}Files
                                </NavLink>
                            </li>
                        </ul>
                    </div>


                    <li onClick={() => handleClick('app1')}>
                        <a href={'#'} className={activeItem === 'app1' ? 'active' : ''}>
                            {ProjectBoard}  Marketing Board
                        </a>
                    </li>

                    <div className={activeItem === 'app1' ? 'submenu active' : 'submenu'}>
                        <ul>
                            <li>
                                <NavLink to="/marketing-list-view">
                                    {ListIcon} List View
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/marketing-board-view">
                                    {ListIcon} Card View
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/growth-board-view">
                                    {MarketingBoard} Service Request
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                    <li  onClick={() => handleClick(null)}>
                        <NavLink to="/meeting-board-view">
                            {MeetingBoard}Meeting Board

                         

                            {role == 'client' && Object.keys(count).length > 0 && setting.meeting == true && count.meeting_count && count.meeting_count > 0 ? <div className={'pointer-circle'} style={{ display: 'inline-block', position: 'relative', color: 'red' }}>
                                <FaCircle color="red" size="10px" />
                            </div> : ''}
                        </NavLink>
                    </li>
                    <li onClick={() => handleClick(null)}>
                        <NavLink to="/invoice-view">
                            {InvoiceBoard}Invoice Board

                            {role == 'client' && Object.keys(count).length > 0 && setting.invoice == true && count.invoice_count && count.invoice_count > 0 ? <div className={'pointer-circle'} style={{ display: 'inline-block', position: 'relative', color: 'red' }}>
                                <FaCircle color="red" size="10px" />
                            </div> : ''}
                        </NavLink>
                    </li>
                    <li onClick={() => handleClick(null)}>
                        <NavLink to="/rewards">
                            {RewardsBoard}Rewards & Points
                        </NavLink>
                    </li>
                    <li onClick={() => handleClick(null)}>
                        <NavLink to="/downloadable-view">
                            {DownloadableBoard} Content Board
                        </NavLink>
                    </li>
                    <li onClick={() => handleClick(null)}>
                        <NavLink to="/referrals-view">
                            {ReferralsBoard} Referrals
                        </NavLink>
                    </li>

                    {role == 'account manager' ?
                        <li onClick={() => handleClick(null)}>
                            <NavLink to="/project-list">
                                {ProjectBoard} Projects
                            </NavLink>
                        </li>
                        : ''
                    }

                    {role == 'account manager' ?
                        <li onClick={() => handleClick(null)}>
                            <NavLink to="/management">
                                {MemberIcon} Clients
                            </NavLink>
                        </li>
                        : ''
                    }

                    <li onClick={() => handleClick(null)}>
                        <NavLink to="/team-member-view">
                            {MemberIcon} Team Members
                        </NavLink>
                    </li>
                    <li onClick={() => handleClick(null)}>
                        <NavLink to="/setting-view">
                            {SettingBoard}Setting
                        </NavLink>
                    </li>
                    <li onClick={() => handleLogOut()}>
                        <a href="#">
                            {LogoutIcon}Logout
                        </a>
                    </li>
                </ul>

            </div>
            <div className="tekrevol_crm_main">
                <div className="top_header">
                    <div className="login_info">
                        Welcome: {user?.user?.first_name + " " + user?.user?.last_name}
                        {/* <span style={{ textTransform: 'capitalize' }}>{role}</span> */}
                    </div>
                    <div className="top_menu">
                        <ul>

                        <li>
                            {manualProjects.length > 1 ? (
                                <div style={{ background: '#fff', padding: '0px' }} className="nav_projects">
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        name="asana_project_id"
                                        label="Project Projects"
                                        id="asana_project_id"
                                        value={selectedProject}
                                        onChange={(e) => handleChange(e) || ''}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                    >
                                        {manualProjects.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            ) : (
                                <span>{manualProjects[0]?.name}</span>
                            )}
                        </li>


                            {/* <li>
                                {SearchIcon}
                            </li>
                            <li>
                                {BellIcon}
                            </li>
                            <li>
                                {CommentsIcon}
                            </li> */}
                            <NavLink to="/setting-view" style={{ textDecoration: 'none' }}>
                                <li>
                                    <div className="profile_img">{user?.user?.image ? <img src={user?.user?.image} alt="DumImg" /> : <div className="img_placeholder">
                                        {getFirstLetters(user?.user?.first_name || 'User')}
                                    </div>}</div>
                                    <div className="profile_info">{user?.user?.first_name} <span>{user?.user?.email}</span></div>

                                    {/* 
                                    <div className="profile_img"><img src={userprofile.image} alt="DumImg" /></div>
                                    <div className="profile_info">{userprofile.first_name} <span>{userprofile.email}</span></div> */}
                                </li>
                            </NavLink>

                        </ul>
                    </div>

                </div>
                <div className="main_container">
                    {role == 'client' && !excludedPaths.includes(location.pathname) && <AddButtonShortcut/> }
                    {props.children}
                </div>
                <div className="bottom_footer">
                    <span>&#169; Tekrevol 2024. All rights reserved</span> - <NavLink to="/terms-and-conditions">Terms and Conditions</NavLink>
                </div>
            </div>
        </div>















        // <div className="flex relative bg-main-bg   dark:bg-main-dark-bg">
        //     {activeMenu ? (
        //         <div className={` ${tag} w-[280px]  md:w-[260px] sm:w-[210px] xm:w-[240px] sidebar  duration-700 bg-main-bg `}>
        //             <Sidebar />
        //         </div>
        //     ) : (
        //         <div className={` w-28  sidebar  bg-main-bg  pr-20 duration-700`}>
        //             <Sidebar />
        //         </div>
        //     )}
        //     <div
        //         className={
        //             activeMenu
        //                 ? 'dark:bg-main-dark-bg  flex-1 duration-700   bg-main-bg   '
        //                 : 'bg-main-bg dark:bg-main-dark-bg  duration-700 flex-1  '
        //         }
        //     >
        //         <div className="sticky top-0  navbar w-full">
        //             <Navbar sessionProject={sessionProject} />
        //         </div>
        //         {props.children}
        //         <Footer />
        //     </div>
        // </div>
    );
}

export default DashboardLayout;
