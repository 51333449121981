import React, {useCallback, useRef} from "react";
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import { NavLink } from "react-router-dom";
import Rewards from "../Rewards";
import star from '../../data/star.svg'
import EditIcon from '../../data/editIcon.svg'
import MarketingProjects from "./MarketingProjects";
import TaskTable from "../Tasks/TaskTable";
import {DateFormatWithSlash} from "../../utils/DateFormatWithSlash";
import {useStateContext} from "../../contexts/ContextProvider";
import {MdCreateNewFolder} from 'react-icons/md';
import {Link} from "react-router-dom";
import {useEffect} from "react";
import axios from "axios";
import {useState} from "react";
import WhatsNew from "../WhatsNew";
import TaskAttachments from "../Tasks/TaskAttachments";
import {useMemo} from "react";
import {ThreeDots} from 'react-loader-spinner'
import {RiEditBoxFill} from 'react-icons/ri';
import {MdDelete} from 'react-icons/md';
import Swal from 'sweetalert2';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {useFormik} from 'formik';
import {Oval} from 'react-loader-spinner'
import * as yup from 'yup';
import {
    TextField,
    MenuItem,
    InputLabel,
    FormControl,
    Select,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel
} from '@material-ui/core';
import DialogTitle from '@mui/material/DialogTitle';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import Slide from '@mui/material/Slide';
import ProjectProgress from '../charts/ProjectProgress';
import dayjs from 'dayjs';
import MarketingProjectProgress from "../charts/MarketingProjectProgress";



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    name: yup
        .string()
        .min(6, 'Too Short!')
        .max(70, 'Too Long!')
        .required('Project Name is required'),
    category_type: yup
        .string()
        .required('Category Type is required'),
    phase_type: yup
        .string()
        .required('Phase Type is required'),
    project_type: yup
        .string()
        .required('Project Type is required'),
    desc: yup
        .string()
        .min(6, 'Too Short!')
        .max(70, 'Too Long!')
        .required('Description is required'),
    deadline: yup
        .date().required('Deadline is required'),

});


const MarketingOverview = () => {
    const location = useLocation();
    //const details = location.state?.project
    const {user, client, project, selectedProject} = useStateContext();
    const [details, setDetails] = useState(selectedProject);
    const navigate = useNavigate();
    const {pid} = useParams();
    const {role} = user.user;
    const [projecttasks, setprojectTasks] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [editValues, setEditValues] = useState({});
    const [projectType, setProjectType] = useState([]);
    const [categoryType, setCategoryType] = useState([]);
    const [phaseType, setPhaseType] = useState([]);
    const [pageHeading, setPageHeading] = useState('Marketing');
    const detailsRef = useRef(null);

    // useEffect(() => {
    //     setSelectedTask([])
    //     getProjectById()
    // }, [selectedProject])

    useEffect(() => {
        if(location.pathname){
            if(location.pathname === '/growth-board-view'){
                setPageHeading('Service')
            }else{
                setPageHeading('Marketing')
            }
        }
    }, [])

    const getProjectById = () => {
        if (user) {
            let url = `${process.env.REACT_APP_BASE_URL}manual-project/${selectedProject}`;
            axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    setDetails(res?.data?.data);
                })
                .catch(err => {
                    if (err.response.status == 401) {
                        localStorage.removeItem('tekrevol_user');
                        window.location.href = '/signin';
                    } else {
                        console.log(err);
                    }
                })
        }
    }

    const getAllProjectTasks = (loader = false) => {
        setLoading(loader);

        axios.get(`${process.env.REACT_APP_BASE_URL}project-task?task_by_project=${selectedProject}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((res) => {
            setprojectTasks(res.data.data);

            if (res?.data?.data?.length > 0) {
                setSelectedTask(res.data.data[0])
            }
        })
        .catch(err => {
            console.log('err====', err);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const Refresher = useCallback(() => {
        getAllProjectTasks();
    }, [projecttasks])

    /*useEffect(() => {
        getAllProjectTasks()
    }, [project])*/

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteProject(id)
            }
        })

    }

    const handleClickOpen = (item) => {

        console.log(item);
        setEditValues(item);
        // // console.log(item);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        // console.log(editValues);
    };

    const formik = useFormik({
        initialValues: {
            name: editValues.name || '',
            category_type: editValues.category?.name || '',
            phase_type: editValues.phase?.name || '',
            project_type: editValues.project_type?.name || '',
            deadline: dayjs(editValues.deadline) || '',
            desc: editValues.description || ''
        },
        validationSchema: validationSchema,
        onSubmit: (values, {resetForm}) => {
            handleClose()
            EditProject()
            resetForm({values: ''})
        },
    });

    const getProjectType = () => {
        if (user) {
            axios.get(`${process.env.REACT_APP_BASE_URL}project-type`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    setProjectType(res.data.data)
                })
                .catch(err => {
                    console.log('err====', err);
                })
        }
    }

    const getCategoryType = () => {
        if (user) {
            axios.get(`${process.env.REACT_APP_BASE_URL}project-category`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    setCategoryType(res.data.data)
                })
                .catch(err => {
                    console.log('err====', err);
                })
        }
    }

    const getPhaseType = () => {
        if (user) {
            axios.get(`${process.env.REACT_APP_BASE_URL}project-phase`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    setPhaseType(res.data.data)
                })
                .catch(err => {
                    console.log('err====', err);
                })
        }
    }

    const EditProject = async () => {
        setLoading(true);
        const selectedProjectType = projectType.find(item => `${item.name}` === formik.values.project_type);
        const selectedCategoryType = categoryType.find(item => `${item.name}` === formik.values.category_type);
        const selectedPhaseType = phaseType.find(item => `${item.name}` === formik.values.phase_type);

        const data = {
            "name": formik.values.name,
            "category_id": selectedCategoryType.id,
            "phase_id": selectedPhaseType.id,
            "type_id": selectedProjectType.id,
            "business_dev": user.user.id,
            "client_id": editValues.client.id,
            "deadline": new Date(formik.values.deadline)?.toUTCString(),
            "description": formik.values.desc
        }

        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}manual-project/${editValues.id}`, {...data}, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        navigate('/marketing')
                        // getAllManualProjects();
                        setLoading(false);
                        Swal.fire(
                            {
                                icon: 'success',
                                title: 'Project Updated successfully!',
                                showConfirmButton: false,
                                timer: 1000
                            }
                        )
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log("ERROR EDITING PROJECT", error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1000
                    })
                })
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const deleteProject = async (id) => {
        setLoading(true)
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}manual-project/${id}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        navigate('/marketing')
                        setLoading(false);
                        Swal.fire(
                            'Deleted!',
                            'Project has been deleted.',
                            'success'
                        )
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        showConfirmButton: false,
                        timer: 1000
                    })
                })
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    /*useEffect(() => {
        setSelectedTask([])
        if (projecttasks.length > 0) {
            setSelectedTask(projecttasks[0])
        }
    }, [project, projecttasks])*/

    // useEffect(() => {
    //     getAllProjectTasks(true);
    //     getProjectType();
    //     getCategoryType();
    //     getPhaseType();
    // }, [selectedProject])

    useEffect(() => {
        formik.setValues({
            name: editValues.name || '',
            category_type: editValues.category?.name || '',
            phase_type: editValues.phase?.name || '',
            project_type: editValues.project_type?.name || '',
            deadline: dayjs(editValues.deadline) || '',
            desc: editValues.description || ''
        });
    }, [selectedProject, editValues]);

    const getSelectedTasks = (taskid) => {
        let tasktobeSelected = projecttasks.find(el => el.id === taskid);
        setSelectedTask(tasktobeSelected)
        if (detailsRef.current) {
            detailsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <>
        <div>
            {
                loading ?
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }} className='mt-5'>
                        <Oval
                            visible={true}
                            height="20"
                            width="20"
                            color="#f37a20"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                        <small>Please wait while the content is loading</small>
                    </div> :

                    <div className="appboard-grid">
                        {details && <div className={`appboard-sub-grid`}>
                            <div className="main-top-banner main_top_title">
                                <div className="main-title">
                                    <h1>{pageHeading}<span> Request </span></h1>
                                </div>
                                <div className="main_btn_txt">
                                    <ul>
                                        <li>
                                            <NavLink to="">
                                                Dashboard
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="">
                                                Appboard
                                            </NavLink>
                                        </li>
                                        <li className="active">
                                            <NavLink to="" >
                                                {pageHeading} Board
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="list-view-project-box ">
                                <TaskTable
                                    refresher={Refresher}
                                    project_id={selectedProject}
                                    selectedTask={selectedTask}
                                    tasks={projecttasks}
                                    getSelectedTasks={getSelectedTasks}
                                    loading={loading}
                                    setLoading={setLoading}
                                />
                                {/* {selectedTask && selectedTask?.name ? (
                                        <div className="marketing_borad_dtails" ref={detailsRef}>

                                            <div className="marketing_borad_item">
                                                <div className="class_img">
                                                    <div className="img_icon">
                                                        {selectedTask?.name && selectedTask.name.charAt(0).toUpperCase()}
                                                    </div>
                                                </div>
                                                <div className="class_txt">
                                                    <h5>{selectedTask?.name || ''}</h5>
                                                    <p>{selectedTask?.description || ''}</p>
                                                    <ul>
                                                        <li><span className="name_title">Status :</span>
                                                            {role === 'client' && selectedTask.status === 0 && (
                                                                <span className="success">Request</span>
                                                            )}
                                                            {role === 'client' && selectedTask.status === 1 && (
                                                                <span className="success">Request In Reveiw</span>
                                                            )}
                                                            {role !== 'client' && selectedTask.status === 1 && (
                                                                <span className="primary">Request Recieved</span>
                                                            )}
                                                            {selectedTask.status === 2 && (
                                                                <span className="success">Task in Progress</span>
                                                            )}
                                                            {selectedTask.status === 3 && (
                                                                <span className="success">Task Completed</span>
                                                            )}
                                                        </li>
                                                        <li className="date_listing"><span className="name_title">Date Started :</span>
                                                            <span>{selectedTask.start_date ? DateFormatWithSlash(selectedTask.start_date) : 'No date added'}</span>
                                                        </li>
                                                        <li className="date_listing"><span
                                                            className="name_title">Deadline :</span><span>{selectedTask.end_date ? DateFormatWithSlash(selectedTask.end_date) : 'No date added'}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="marketing_borad_item">
                                                {selectedTask && selectedTask?.name ?
                                                    <TaskAttachments task={selectedTask}/>
                                                    : ''}
                                            </div>
                                        </div>
                                    )
                                    : ''
                                } */}

                            </div>
                        </div>
                        }
                        <Dialog
                            open={open}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle>{"Edit a Project"}</DialogTitle>
                            <DialogContent>
                                <form onSubmit={formik.handleSubmit} className='space-y-6 '>
                                    <TextField
                                        className='w-[550px] tab:w-[100%] mobile:w-[100%] small:w-[100%] '
                                        id="name"
                                        name="name"
                                        label="Project Name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        helperText={formik.touched.name && formik.errors.name}
                                    />
                                    <div
                                        className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Project Category</InputLabel>
                                            <Select
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                name="category_type"
                                                label="Project Category"
                                                id="category_type"
                                                value={formik.values.category_type}
                                                onChange={formik.handleChange}
                                                error={formik.touched.category_type && Boolean(formik.errors.category_type)}
                                                helperText={formik.touched.category_type && formik.errors.category_type}
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left"
                                                    },
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "left"
                                                    },
                                                    getContentAnchorEl: null
                                                }}
                                            >
                                                {categoryType?.map((item, index) => {
                                                    return (

                                                        <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>

                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Project Phase</InputLabel>
                                            <Select
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                name="phase_type"
                                                label="Project Phase"
                                                id="phase_type"
                                                value={formik.values.phase_type}
                                                onChange={formik.handleChange}
                                                error={formik.touched.phase_type && Boolean(formik.errors.phase_type)}
                                                helperText={formik.touched.phase_type && formik.errors.phase_type}
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left"
                                                    },
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "left"
                                                    },
                                                    getContentAnchorEl: null
                                                }}
                                            >
                                                {phaseType?.map((item, index) => {
                                                    return (

                                                        <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>

                                    </div>
                                    <div
                                        className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Project Type</InputLabel>
                                            <Select
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                name="project_type"
                                                label="Project Type"
                                                id="project_type"
                                                value={formik.values.project_type}
                                                onChange={formik.handleChange}
                                                error={formik.touched.project_type && Boolean(formik.errors.project_type)}
                                                helperText={formik.touched.project_type && formik.errors.project_type}
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left"
                                                    },
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "left"
                                                    },
                                                    getContentAnchorEl: null
                                                }}
                                            >
                                                {projectType?.map((item, index) => {
                                                    return (

                                                        <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                        <FormControl className="custom-input" fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker', 'DatePicker']}>
                                                    <DatePicker
                                                        fullWidth
                                                        className='w-[100%]'
                                                        name="deadline"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        id="deadline"
                                                        inputFormat="E MMM dd yyyy HH:MM:SS O"
                                                        label="Deadline"
                                                        value={formik.values.deadline}
                                                        onChange={value => formik.setFieldValue('deadline', value)}
                                                        error={formik.touched.deadline && Boolean(formik.errors.deadline)}


                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </FormControl>

                                    </div>

                                    <div
                                        className='flex gap-8 tab:inline tab:space-y-8 mobile:inline mobile:space-y-8 small:inline small:space-y-8'>
                                        <TextField
                                            className='w-[550px]  tab:w-[100%] mobile:w-[100%] small:w-[100%]'
                                            id="desc"
                                            name="desc"
                                            label="Description"
                                            value={formik.values.desc}
                                            onChange={formik.handleChange}
                                            error={formik.touched.desc && Boolean(formik.errors.desc)}
                                            helperText={formik.touched.desc && formik.errors.desc}
                                        />
                                    </div>
                                    <div className='flex justify-end mt-3'>
                                        <button className='bg-[#F37A20] w-[70px] rounded-md text-[16px] p-3 text-white'
                                                variant="contained" fullWidth type="submit">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </DialogContent>

                        </Dialog>
                    </div>
            }
        </div>
        </>
    );
};


export default MarketingOverview;
